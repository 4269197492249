import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import axios from "axios";
import mixin from '@/mixins'

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		component: () => import("../views/Index.vue"),
		children: [
			{
				name: "Home",
				path: "",
				component: () => import("../views/Home.vue"),
			},
		],
	},
	{
		path: "/camera",
		component: () => import("../views/Index.vue"),
		children: [
			{
				name: "Camera",
				path: "",
				component: () => import("../views/Camera.vue"),
			},
		],
	},
	{
		path: "/place/:id*",
		component: () => import("../views/Index.vue"),
		props: true,
		children: [
			{
				name: "Place",
				path: "",
				component: () => import("../views/Place.vue"),
			},
		],
	},
	{
		path: "/images/:id*",
		component: () => import("../views/Index.vue"),
		props: true,
		children: [
			{
				name: "Images",
				path: "",
				component: () => import("../views/Images.vue"),
			},
		],
	},
	{
		path: "/timelapse/:id*",
		component: () => import("../views/Index.vue"),
		props: true,
		children: [
			{
				name: "Timelapse",
				path: "",
				component: () => import("../views/Timelapse.vue"),
			},
		],
	},
	{
		path: "/mypage",
		component: () => import("../views/MypageIndex.vue"),
		children: [
			{
				name: "Mypage",
				path: "",
				component: () => import("../views/Mypage.vue"),
			},
		],
	},
	{
		path: "/login",
		component: () => import("../views/LoginIndex.vue"),
		children: [
			{
				name: "Login",
				path: "",
				component: () => import("../views/Login.vue"),
			},
		],
	},
	{
		path: "/start",
		component: () => import("../views/LoginIndex.vue"),
		children: [
			{
				name: "Start",
				path: "",
				component: () => import("../views/Start.vue"),
			},
		],
	},
	{
		path: "/register",
		component: () => import("../views/LoginIndex.vue"),
		children: [
			{
				name: "Register",
				path: "",
				component: () => import("../views/Register.vue"),
			},
		],
	},
	{
		path: "/admin/user",
		component: () => import("../views/AdminIndex.vue"),
		children: [
			{
				name: "AdminUser",
				path: "",
				component: () => import("../views/AdminUser.vue"),
			},
		],
	},
	{
		path: "/admin/inviteUser/:id*",
		component: () => import("../views/AdminIndex.vue"),
		children: [
			{
				name: "AdminInviteUser",
				path: "",
				component: () => import("../views/AdminInviteUser.vue"),
			},
		],
	},
	{
		path: "/admin/project",
		component: () => import("../views/AdminIndex.vue"),
		children: [
			{
				name: "AdminProject",
				path: "",
				component: () => import("../views/AdminProject.vue"),
			},
		],
	},
	{
		path: "/admin/editProject/:id*",
		component: () => import("../views/AdminIndex.vue"),
		children: [
			{
				name: "AdminEditProject",
				path: "",
				component: () => import("../views/AdminEditProject.vue"),
			},
		],
	},
	{
		path: "/admin/place",
		component: () => import("../views/AdminIndex.vue"),
		children: [
			{
				name: "AdminPlace",
				path: "",
				component: () => import("../views/AdminPlace.vue"),
			},
		],
	},
	{
		path: "/admin/editPlace/:id*",
		component: () => import("../views/AdminIndex.vue"),
		children: [
			{
				name: "AdminEditPlace",
				path: "",
				component: () => import("../views/AdminEditPlace.vue"),
			},
		],
	},
	{
		path: "/admin/camera",
		component: () => import("../views/AdminIndex.vue"),
		children: [
			{
				name: "AdminCamera",
				path: "",
				component: () => import("../views/AdminCamera.vue"),
			},
		],
	},
	{
		path: "/admin/editCamera/:id*",
		component: () => import("../views/AdminIndex.vue"),
		children: [
			{
				name: "AdminEditCamera",
				path: "",
				component: () => import("../views/AdminEditCamera.vue"),
			},
		],
	},
	{
		path: "/error",
		name: "Error",
		component: () => import("../views/Error.vue"),
	},
	{
		path: "*",
		redirect: "/",
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	// store.dispatch(
	// 	"auth/setData",
	// 	{ tokenType: "line", token: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJVMWU0NjM4Y2E5YjY1YTAwYmMxYTM0ZDY1MmE5MjYwOTAiLCJlbWFpbCI6InBhcGlrYXRAaG90bWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJjaWQiOlsiODYxMTA3MDM5ODAyNDI3IiwiODYxMTA3MDM5ODA0Mzk5IiwiODYxMTA3MDM2NTcxODM1IiwiODYxMTA3MDM5ODA0MDg0Il0sInRva2VuIjoiZXlKaGJHY2lPaUpJVXpJMU5pSjkubmFYZlhpUWotUElpc0hkTlVXTElmd0lRZHhBSzhJLXJHUVlSS2k5RkRVVjlrbE9RZDBSVnFSVi0wUEE0SmV2LVhpSzdrR1UzMDdMN21MczlOYXg5M1lDR3l2czRRelBiRGNwVzB4MHlhVzhNSi1EMGIyRUZFbDM4UGJjdFhra2J5NUhYY0VOSmE2VWtmLUkwSXZDOVV6OUMwQlkwaFlrYWlpakJPMG01S3JJLkRhM0VVb281XzQ0YnNGbldSZEJkU0dJem1mV1RVdV9pYVJ5N0lsVnFYdU0iLCJ0b2tlblR5cGUiOiJsaW5lIiwiaWF0IjoxNjE5OTcxMDY4LCJleHAiOjE2MjE2OTkwNjh9.wpsmj4fnKBcPr1GdvJHWffQ6oAS66yMozNDTrPM6_fVnkL7dVzlthyhG2_WOG3nmVuncuJKhAsoBEIGataYen-ZSqE_2QH06TJgd4iL28R6HyLyJqM5rZbwXlCpmqjnB8B1JLfQoXBwjH6MMRHZ0c4GujD3CF9SswAMFQHnBbJQqSSo4gMSrPA9m5rZ4EXN11RKQpO36aN3og03h7V9gJof-UXxeRAuMYt3dPe2mUdhMH-HdQtcae8GS0y29LG_Fdot6V7LhAPPZx07tYFDv8nhR1i4OT4Y2xI3TCd2bJMmw3drjOAkV6HlR1BK9JrsCH1Xwfd-RSdYBDLswqeLXwMPwrXayW79Gae8wx5I3h15BDJh0SVi1eEasSuRr9c5mwuQBulPt4RBClLpunnQUDD_OzOblVHsylQ7cSS1j6OMSNjwCrOeEfay-Tq7RJOLa8sLlal3M647YPXFV-ptDR0ePDVVRHyTGxjjBTsQ1HFm2fHXG7wasDsuwjY41fOe70rUo0lcUvEm3Iux3UuVJjlQh8DdrTzOrmUjfFWj-LuJdUzy9bGZeg1a5EaN5-51eulKVe0HSAYpm9MvN07IhR2GDoiWZrHDPHmhWkzfsK95Gl9dBWowSL0-hLh0KbbQTK4eLRUas-9UMrP00atFHRgA8bBbBK_5puGLl5cqm7hs" }
	// );
	// Homeの場合のみ、サーバから得られる最新のアプリ情報に更新する
	if (to.name == "Home") {
		// Cookieを持っていた場合（ログイン直後）はCookie中のTokenをlocalStorageへ保存する
		if (!store.state.auth.data.token && store.state.auth.data.action != "logout" && Vue.cookie.get("token") && Vue.cookie.get("tokenType")) {
			console.log("Set cookie token to localStorage: " + Vue.cookie.get("tokenType"));
			store.dispatch("auth/setData", { tokenType: Vue.cookie.get("tokenType"), token: Vue.cookie.get("token") });
		}
		// localStorageにJWT Tokenを持っていない場合はログイン画面へリダイレクト
		if (!store.state.auth.data.token) {
			next({ path: "/login" });
			return;
		}
		// console.log("Token: " + store.state.auth.data.token);
		// APIを叩いて情報を更新する
		store.commit('SET_LOADING', true);
		axios
			.get("https://" + mixin.data().config.defaultOriginString + "/api/appinfo?tokenType=" + store.state.auth.data.tokenType, {
				headers: {
					Authorization: "Bearer " + store.state.auth.data.token,
				},
			})
			.then((res) => {
				store.commit('SET_LOADING', false);
				// 成功した場合
				if (res && res.data && res.data.status == "success") {
					console.log("Success: " + JSON.stringify(res.data));
					// プロフィール情報とtokenを更新する
					store.dispatch("user/setData", res.data.appInfo.user);
					store.dispatch("project/setData", res.data.appInfo.project);
					store.dispatch("places/setData", res.data.appInfo.places);
					store.dispatch("auth/setData", { tokenType: res.data.tokenType, token: res.data.token });
					next();
				} else {
					console.log("Fail: " + JSON.stringify(res));
					// 認証失敗の場合はログアウト処理をしてログインページへ転送
					store.dispatch("auth/unsetData");
					store.dispatch("user/unsetData");
					store.dispatch("project/unsetData");
					store.dispatch("places/unsetData");
					next({ path: "/login" });
				}
			})
			.catch((error) => {
				store.commit('SET_LOADING', false);
				console.log("Fail2: " + error);
				store.dispatch("auth/unsetData");
				store.dispatch("user/unsetData");
				store.dispatch("project/unsetData");
				store.dispatch("places/unsetData");
				next({ path: "/login" });
			});
	} else {
		// その他のページではとりあえずTokenを持っているかどうかのみチェックする
		if (to.name === "Login") {
			store.dispatch("auth/resetAction");
			next();
		} else if (store.state.auth.data.token) {
			next();
		} else {
			// Tokenを持っていない場合はログイン画面へリダイレクトする
			if (to.name === "Start" || to.name === "Register") {
				next();
			} else {
				next({ path: "/login" });
			}
		}
	}
});

router.afterEach((to, from) => { });

export default router;
