
const state = {
	data: {
		id: "",
		name: "",
		description: ""
	}
};

const mutations = {
	UNSET_DATA: (state) => {
		state.data.id = "";
		state.data.name = "";
		state.data.description = "";
	},
	SET_DATA: (state, payload) => {
		state.data.id = payload.id;
		state.data.name = payload.name;
		state.data.image = payload.description;
	}
};
const actions = {
	unsetData({ commit }) {
		commit("UNSET_DATA");
	},
	setData({ commit }, payload) {
		commit("SET_DATA", payload);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
