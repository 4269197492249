import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import DatetimePicker from "vuetify-datetime-picker";
import NProgress from 'vue-nprogress';
import VueCookie from 'vue-cookie';
import Mixin from '@/mixins' 
import Chat from 'vue-beautiful-chat'

Vue.mixin(Mixin)
Vue.use(Chat)

Vue.config.devtools = true;
Vue.config.performance = true;
Vue.config.productionTip = false;

Vue.use(DatetimePicker);
Vue.use(NProgress);
Vue.use(VueCookie);

const nprogress = new NProgress();

new Vue({
	router,
	vuetify,
	store,
	nprogress,
	render: (h) => h(App),
}).$mount("#app");
