
const state = {
	data: []
};

const mutations = {
	UNSET_DATA: (state) => {
		state.data = [];
	},
	SET_DATA: (state, payload) => {
		state.data = payload;
	}
};
const actions = {
	unsetData({ commit }) {
		commit("UNSET_DATA");
	},
	setData({ commit }, payload) {
		commit("SET_DATA", payload);
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
