import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./module/auth";
import project from "./module/project";
import user from "./module/user";
import places from "./module/places";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		project,
		user,
		places
	},
	plugins: [
		createPersistedState({
			key: "fieldshareApp",
		}),
	],
	state: {
		isLoading: false,
		isStartPage: false
	},
	getters: {
		getPlaceById: (state) => (id) => {
			return state.places.data.find((item) => item.id === id);
		},
		getPlaceMarkers: (state) => {
			var s = state.places.data.map((item) => {
				let obj = {};
				obj["name"] = item.name;
				obj["latitude"] = parseFloat(item.latitude);
				obj["longitude"] = parseFloat(item.longitude);
				return obj;
			});
			return s;
		},
	},
	mutations: {
		SET_LOADING: (state, flag) => {
			state.isLoading = flag;
		},
		SET_STARTPAGE: (state, flag) => {
			state.isStartPage = flag;
		}
	},
	actions: {
	},
});
